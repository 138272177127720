// 读取配置json
let xhr = new XMLHttpRequest()
xhr.open('GET', '/param.json', false)
xhr.overrideMimeType("text/html;charset=utf-8")
xhr.send(null)

let param = JSON.parse(xhr.responseText)
// 项目名
const projectName = param.projectName

// cdn
const cdn = param.cdn || ''

//cdnCode
const code = param.code
// ES固定上线时间  判断上线之前数据显示问题
const esTime = param.esTime
// api（PC的）
const apiUrl = param.apiUrl + '/api'
// const apiUrl = param.apiUrl + '/apiadmin'
const apiPc = param.apiUrl

// "apiUrl": "http://192.168.1.160:80",
const redPay = param.redPay

// 唯一标识
const Ident = 'f7cc08774ad44ecbccf2977168e8e5c2'

// 查询所有时，pageSize值
const pageSizeAll = param.pageSizeAll

// 输出
export default {
    projectName,
    cdn,
    code,
    apiUrl,
    apiPc,
    pageSizeAll,
    Ident,
    esTime,
    redPay
}