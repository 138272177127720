import { createRouter, createWebHistory } from 'vue-router'
import HomeNav from '@/components/HomeNav.vue'
import store from '@/store'
import common from '@/common/common.js'
import lang from "@/common/language.js"
import login from '@/api/login.js'

// 'Course' 为课程管理员   功能权限为   1.系统用户-经销商分组
//                                     2. 题库管理;
//                                     3.视频库;
//                                     4.直播管理;
//                                     5.系统设置;
//                                     6.今日数据
//                                     7.历史数据

// 'Customer' 客户管理员    功能权限为  1.历史数据
//                                    2.系统用户-回收站
//                                    3.经销商管理
//                                    4.群管理员管理
//                                    5.会员管理
//                                    6.系统设置

// 'Finance' 财务    功能权限为         1历史数据
//                                    2.经销商列表-充值提取
//                                    3.资源消耗记录
//                                    4.财务管理

export const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/LoginView.vue'),
    meta: {
      hiddenMenu: true,
      noLogin: true
    }
  },
  {
    path: '/',
    component: HomeNav,
    redirect: '/index', // 默认重定向到 /index
    meta: {
      hiddenMenu: false,
      noLogin: false
    },
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index/IndexView2.vue'),
        meta: {
          title: '今日数据',
          icon: 'icon-chart-histogram',
          guard: ['Admin', 'Super', 'Dealers', 'Course',"Branch"],
          show: ['Admin', 'Super','Course'],
          agentshow:['Dealers',"Branch"],
        },
      },
      {
        path: '/statistic',
        name: 'statistic',
        meta: {
          title: '历史数据',
          icon: 'icon-trend-two',
          guard: ['Admin', 'Super', 'Dealers', 'Customer', 'Finance', 'Course',"Branch"]
        },
        children: [
          {
            path: 'dailyData',
            name: 'dailyData',
            component: () => import('@/views/statistic/DailyData.vue'),
            meta: {
              title: '每日统计',
              icon: 'icon-chart-pie-one',
              guard: ['Admin', 'Super', 'Customer', 'Finance', 'Course',"Branch"]
            }
          }, 
          {
            path: 'platformData',
            name: 'platformData',
            component: () => import('@/views/statistic/PlatformData.vue'),
            meta: {
              title: `${lang.dealers}每日统计`,
              icon: 'icon-date-comes-back',
              guard: ['Admin', 'Super', 'Customer', 'Finance', 'Course',"Branch"]
            }
          },
          {
            path: 'dealersData',
            name: 'dealersData',
            component: () => import('@/views/statistic/DealersData.vue'),
            meta: {
              title: `${lang.dealers}每日统计`,
              icon: 'icon-internal-data',
              guard: ['Dealers']
            }
          }
        ]
      },
      {
        path: '/manage',
        name: 'manage',
        meta: {
          title: '系统用户',
          icon: 'icon-peoples',
          guard: ['Admin', 'Super', 'Course', 'Customer']
        },
        children: [
          {
            path: 'dearler',
            name: 'Dearler',
            component: () => import('@/views/account/Dearler.vue'),
            meta: {
              title: '添加经销商',
              icon: 'icon-user',
              guard: ['Admin', 'Super'],
              add: ['Admin', 'Super'],
              edit: ['Admin', 'Super'],
              status: ['Admin', 'Super'],
              del: ['Admin', 'Super']
            }
          },
          {
            path: 'admin',
            name: 'AdminView',
            component: () => import('@/views/account/AdminView.vue'),
            meta: {
              title: '员工管理',
              icon: 'icon-user',
              guard: ['Admin', 'Super'],
              add: ['Admin', 'Super'],
              edit: ['Admin', 'Super'],
              status: ['Admin', 'Super'],
              del: ['Admin', 'Super']
            }
          },
          {
            path: 'dealersGroup',
            name: 'dealersGroup',
            component: () => import('@/views/manage/DealersGroup.vue'),
            meta: {
              title: `${lang.dealers}分组`,
              icon: 'icon-schedule',
              guard: ['Admin', 'Super', 'Course'],
              add: ['Admin', 'Super', 'Course'],
              edit: ['Admin', 'Super', 'Course'],
              del: ['Admin', 'Super', 'Course']
            }
          },
          {
            path: 'recycleBin',
            name: 'recycleBin',
            component: () => import('@/views/manage/RecycleBin.vue'),
            meta: {
              title: '回收站',
              icon: 'icon-recycle-bin',
              guard: ['Admin', 'Super', 'Customer'],
            }
          }
        ]
      },
      {
        path: '/question',
        name: 'question',
        meta: {
          title: '题库管理',
          icon: 'icon-notebook-one',
          guard: ['Admin', 'Super', 'Course']
        },
        children: [
        //   {
        //     path: 'questionList',
        //     name: 'questionList',
        //     component: () => import('@/views/question/QuestionList.vue'),
        //     meta: {
        //       title: '问题列表',
        //       icon: 'icon-notebook-and-pen',
        //       guard: ['Admin', 'Super', 'Course'],
        //       add: ['Admin', 'Super', 'Course'],
        //       edit: ['Admin', 'Super', 'Course'],
        //       del: ['Admin', 'Super', 'Course']
        //     }
        //   },
          {
            path: 'questionBank',
            name: 'questionBank',
            component: () => import('@/views/question/QuestionBank.vue'),
            meta: {
              title: '题库列表',
              icon: 'icon-book-one',
              guard: ['Admin', 'Super', 'Course'],
              list: ['Admin', 'Super', 'Course'],
              add: ['Admin', 'Super', 'Course'],
              edit: ['Admin', 'Super', 'Course'],
              del: ['Admin', 'Super', 'Course']
            }
          }
        ]
      },
      {
        path: '/material',
        name: 'material',
        meta: {
          title: '视频库',
          icon: 'icon-material',
          guard: ['Admin', 'Super', 'Course']
        },
        children: [
          {
            path: 'videoCategory',
            name: 'videoCategory',
            component: () => import('@/views/material/VideoCategory.vue'),
            meta: {
              title: '视频分类',
              icon: 'icon-movie',
              guard: ['Admin', 'Super', 'Course'],
              add: ['Admin', 'Super', 'Course'],
              del: ['Admin', 'Super', 'Course'],
              edit: ['Admin', 'Super', 'Course']
            }
          },
          {
            path: 'videoList',
            name: 'videoList',
            component: () => import('@/views/material/VideoList.vue'),
            meta: {
              title: '视频管理',
              icon: 'icon-video',
              guard: ['Admin', 'Super', 'Course'],
              add: ['Admin', 'Super', 'Course'],
              del: ['Admin', 'Super', 'Course'],
              edit: ['Admin', 'Super', 'Course'],
              gl: ['Admin', 'Super', 'Course']
            }
          }
        ]
      },
      {
        path: '/activity',
        name: 'activity',
        meta: {
          title: '直播管理',
          icon: 'icon-video-two',
          guard: ['Admin', 'Super', 'Dealers', 'Course']
        },
        children: [
          {
            path: 'activityList',
            name: 'activityList',
            component: () => import('@/views/activity/ActivityList.vue'),
            meta: {
              title: '直播列表',
              icon: 'icon-play-two',
              guard: ['Admin', 'Super', 'Dealers', 'Course'],
              add: ['Admin', 'Super', 'Course'],
              edit: ['Admin', 'Super', 'Course'],   // 编辑
              del: ['Admin', 'Super', 'Course'],    // 回收站
              copy: ['Admin', 'Super', 'Course'],   // 复制
              upDown: ['Admin', 'Super', 'Course'],  // 上下架
              tt: ['Admin', 'Super', 'Dealers'],  // 团队/统计,
              upload: ['Admin', 'Super', 'Course'],  // 导出
            }
          },
          {
            path: 'activityCategory',
            name: 'activityCategory',
            component: () => import('@/views/activity/ActivityCategory.vue'),
            meta: {
              title: '直播分类',
              icon: 'icon-category-management',
              guard: ['Admin', 'Super', 'Course'],
              add: ['Admin', 'Super', 'Course'],
              edit: ['Admin', 'Super', 'Course'],
              del: ['Admin', 'Super', 'Course'],
            }
          }
        ]
      },
      {
        path: '/course',
        name: 'course',
        meta: {
          title: '课程集管理',
          icon: 'icon-video-two',
          guard: ['Admin', 'Super', 'Course','Dealers']
        },
        children: [
          {
            path: 'courseList',
            name: 'courseList',
            component: () => import('@/views/course/CourseList.vue'),
            meta: {
              title: '课程集列表',
              icon: 'icon-category-management',
              guard: ['Admin', 'Super', 'Course','Dealers'],
              add: ['Admin', 'Super', 'Course'],
              edit: ['Admin', 'Super', 'Course'],   // 编辑
              del: ['Admin', 'Super', 'Course'],    // 回收站
              copy: ['Admin', 'Super', 'Course'],   // 复制
              upDown: ['Admin', 'Super', 'Course'],  // 上下架
              tt: ['Admin', 'Super', 'Dealers','Course'],  // 团队/统计,
              upload: ['Admin', 'Super', 'Course'],  // 导出
            }
          },
          {
            path: 'courTable',
            name: 'courTable',
            component: () => import('@/views/course/CourTable.vue'),
            meta: {
              title: '课程列表',
              icon: 'icon-category-management',
              guard: ['Admin', 'Super', 'Course','Dealers'],
              add: ['Admin', 'Super', 'Course'],
              edit: ['Admin', 'Super', 'Course'],   // 编辑
              del: ['Admin', 'Super', 'Course'],    // 回收站
              copy: ['Admin', 'Super', 'Course'],   // 复制
              upDown: ['Admin', 'Super', 'Course'],  // 上下架
              tt: ['Admin', 'Super', 'Dealers','Course'],  // 团队/统计,
              upload: ['Admin', 'Super', 'Course'],  // 导出,
              show:['Admin', 'Super', 'Course'], 
            }
          }
        ],
        
      },
      {
        path: '/dealerManagement',
        name: 'dealerManagement',
        meta: {
          title: '经销商管理',
          icon: 'icon-sales-report',
          guard: ['Admin', 'Super', 'Customer', 'Finance']
        },
        children: [
          {
            path: 'dealersList',
            name: 'DealersList',
            component: () => import('@/views/dealerManagement/DealersList.vue'),
            meta: {
              title: `${lang.dealers}列表`,
              icon: 'icon-classroom',
              guard: ['Admin', 'Super', 'Customer', 'Finance'],
              recharge: ['Admin', 'Super', 'Finance'], //充值
              JUMP: ['Admin', 'Super', 'Customer'],  //跳转群管理
              TJ: ['Admin', 'Super', 'Customer'],    // 统计 
              edit: ['Admin', 'Super', 'Customer', 'Finance'],  //状态修改
              rubish: ['Admin', 'Super', 'Customer'],  //回收站
              del: ['Admin', 'Super', 'Customer'],
              zcfs: ['Admin', 'Super', 'Customer'],  // 会员首次进入审核
              changeDealer: ['Admin', 'Super', 'Customer'],  // 更换经销商
              red:['Admin', 'Super', 'Customer'] 
            }
          },
        ]
      },
      {
        path: '/groupLeader',
        name: 'groupLeader',
        meta: {
          title: '群管理员管理',
          icon: 'icon-every-user',
          guard: ['Admin', 'Super', 'Dealers', 'Customer']
        },
        children: [
          {
            path: 'group',
            name: 'Group',
            component: () => import('@/views/groupLeader/Group.vue'),
            meta: {
              title: `群管理员列表`,
              icon: 'icon-user-business',
              guard: ['Admin', 'Super', 'Dealers', 'Customer'],
              change: ['Dealers', 'Customer'],  //更换群管理
              edit: ['Admin', 'Super', 'Dealers', 'Customer'],  // 修改,
              editpl:['Admin', 'Super', 'Customer'],
              export: ['Admin', 'Super', 'Customer', 'Dealers'], //导出
              migrate: ['Admin', 'Super', 'Customer'],   // 跨经销商迁移
              hsz: ['Admin', 'Super', 'Customer'],    //回收站
              link: ['Admin', 'Super', 'Dealers'],  //生成链接
              exportmember: ['Dealers'],
              add: ['Admin', 'Super', 'Customer'],    //添加群管理
            }
          }
        ]
      },
      {
        path: '/member',
        name: 'member',
        meta: {
          title: '会员管理',
          icon: 'icon-wechat',
          guard: ['Admin', 'Super', 'Dealers', 'Customer']
        },
        children: [
          {
            path: 'memberList',
            name: 'memberList',
            component: () => import('@/views/member/MemberList.vue'),
            meta: {
              title: '会员列表',
              icon: 'icon-people-plus',
              guard: ['Admin', 'Super', 'Dealers', 'Customer'],
              del: ['Admin', 'Super', 'Dealers', 'Customer'], //删除
              edit: ['Admin', 'Super', 'Dealers', 'Customer'],  //编辑
              tofix:['Admin', 'Super','Customer']
            }
          },
          {
            path: 'tagList',
            name: 'tagList',
            component: () => import('@/views/member/tagList.vue'),
            meta: {
              title: '标签列表',
              icon: 'icon-people-plus',
              guard: ['Admin', 'Super', 'Dealers'],
              add: ['Admin', 'Super', 'Customer'], //添加
              show: ['Admin', 'Super', 'Dealers'],
              edit: ['Admin', 'Super', 'Customer'],
              del: ['Admin', 'Super', 'Customer']
            }
          }
        ]
      },
      {
        path: '/consumptionRecords',
        name: 'consumptionRecords',
        meta: {
          title: '资源消耗记录',
          icon: 'icon-chart-histogram',
          guard: ['Admin', 'Super', 'Dealers', 'Finance']
        },
        children: [
          {
            path: 'consumeLog',
            name: 'ConsumeLog',
            component: () => import('@/views/consumptionRecords/ConsumeLog.vue'),
            meta: {
              title: '资源消耗日统计',
              icon: 'icon-diamond',
              guard: ['Admin', 'Super', 'Finance']
            }
          },
          {
            path: 'redPacketDetail',
            name: 'RedPacketDetail',
            component: () => import('@/views/consumptionRecords/RedPacketDetail.vue'),
            meta: {
              title: '红包发放明细',
              icon: 'icon-envelope',
              guard: ['Admin', 'Super', 'Dealers', 'Finance']
            }
          },
        ]
      },
      {
        path: '/finance',
        name: 'finance',
        meta: {
          title: '财务',
          icon: 'icon-currency',
          guard: ['Admin', 'Super', 'Finance']
        },
        children: [
          {
            path: 'cdnPay',
            name: 'CdnPay',
            component: () => import('@/views/finance/CdnPay.vue'),
            meta: {
              title: '视频流量充值日志',
              icon: 'icon-flash-payment',
              guard: ['Admin', 'Super', 'Finance']
            }
          },
          {
            path: 'smsPay',
            name: 'SmsPay',
            component: () => import('@/views/finance/SmsPay.vue'),
            meta: {
              title: '短信充值列表',
              icon: 'icon-wallet',
              guard: ['Admin', 'Super', 'Finance']
            }
          },
          {
            path: 'redPacketPay',
            name: 'RedPacketPay',
            component: () => import('@/views/finance/RedPacketPay.vue'),
            meta: {
              title: '红包预存记录',
              icon: 'icon-pay-code-two',
              guard: ['Admin', 'Super', 'Finance'],
              add: ['Admin', 'Super']
            }
          },
          {
            path: 'payLog',
            name: 'PayLog',
            component: () => import('@/views/finance/PayLog.vue'),
            meta: {
              title: '充值列表',
              icon: 'icon-paypal',
              guard: ['Admin', 'Super', 'Finance']
            }
          }
        ]
      },
      {
        path: '/setting',
        name: 'Setting',
        meta: {
          title: '系统设置',
          icon: 'icon-config',
          guard: ['Admin', 'Super', 'Dealers', 'Course', 'Customer']
        },
        children: [
          {
            path: 'systemParameter',
            name: 'SystemParameter',
            component: () => import('@/views/setting/systemParameter.vue'),
            meta: {
              title: '系统参数',
              icon: 'icon-setting-two',
              guard: ['Admin', 'Super']
            }
          },
          {
            path: 'pictureLibrary',
            name: 'pictureLibrary',
            component: () => import('@/views/setting/PictureLibrary.vue'),
            meta: {
              title: '图片管理',
              icon: 'icon-pic',
              guard: ['Admin', 'Super', 'Dealers', 'Course', 'Customer'],
              show: ['Admin', 'Super', 'Course', 'Customer'],
              wjj: ['Admin', 'Super', 'Course', 'Customer'],
            }
          },
          {
            path: 'operationLog',
            name: 'operationLog',
            component: () => import('@/views/setting/OperationLog.vue'),
            meta: {
              title: '操作日志',
              icon: 'icon-log',
              guard: ['Admin', 'Super'],
              show: ['Admin', 'Super'],
              wjj: ['Admin', 'Super'],
            }
          }
        ]
      },
    ]
  },
]
const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.noLogin) {
    // noLogin true，则无登录和权限验证
    if (to.name === 'Login') {
      // 若已登录，进入登录页时直接跳转到首页
      if (store.getters.doneToken) {
        // 重定向到首页
        next('/')
      } else {
        // 若store无token，读取localstorage
        let userInfo = common.getUserInfo()
        if (userInfo.token) {
          // 补充store中的token和role
          store.commit('modifyToken', { token: userInfo.token, expire: userInfo.expire })
          store.commit('modifyRole', userInfo.role)
          // 重定向到首页
          next('/')
        } else {
          next()
        }
      }
    }
  } else {
    // 是否登录
    if (store.getters.doneToken) {
      // 访问权限逻辑
      if (to.meta.guard && to.meta.guard.indexOf(store.state.role) === -1) {
        next('/')
        return false
      }
      // 继续
      next()
    } else {
      // 若store无token，读取localstorage
      let userInfo = common.getUserInfo()
      // let now = Date.parse(new Date()) / 1000;
      if (userInfo.token) {
        // 补充store中的token和role
        store.commit('modifyToken', { token: userInfo.token, expire: userInfo.expire })
        store.commit('modifyRole', userInfo.role)

        // 访问权限逻辑
        if (to.meta.guard && to.meta.guard.indexOf(store.state.role) === -1) {
          next('/')
          return false
        }
        // 继续
        next()
      } else {
        // 清空登录信息
        store.commit('modifyToken', { token: '', expire: 0 })
        store.commit('modifyRole', '')
        common.setUserInfo('')
        common.setSystenInfo('')
        //获取地址栏参数字符串
        let paramStr = window.location.search;
        let params = {};
        // 通过正则表达式或字符串原型方法解析参数字符串
        if (paramStr) {
          paramStr = paramStr.substring(1);
          const paramArr = paramStr.split('&');
          for (let i = 0; i < paramArr.length; i++) {
            const keyValueArr = paramArr[i].split('=');
            params[keyValueArr[0]] = keyValueArr[1];
          }
        }
        if (params.key) {
          login.getCloud({ key: params.key }).then(res => {
            if (res.data.code == 200) {
              let now = Date.parse(new Date()) / 1000;
              let token = {
                token: res.data.data.access_token,
                expire: now + 86400 * 1
              }
              store.commit('modifyToken', token)
              let roles = res.data.data.roles
              let wx = res.data.data.wx
              let info = res.data.data.info
              let userInfo = token
              userInfo.role = roles.role_name
              userInfo.id = roles.id
              userInfo.wxId = 0
              userInfo.nickname = info.nick_name
              userInfo.avatar = info.face
              if (userInfo.wx) {
                userInfo.wx = {
                  id: wx.id,
                  nick_name: wx.nick_name,
                  phone: wx.phone
                }
              }
              // 记录用户信息
              store.commit('modifyRole', roles.role_name)
              common.setUserInfo(userInfo)
              next()
            }
          })
        } else {
          // 重定向回登录页
          next({ name: 'Login' })
        }
      }
    }
    // 占位（无意义）
    from
  }
})

export default router
